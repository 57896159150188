<template>
  <div class="listing-search-criteria mx-3">
    <v-container fluid class="pt-0">
      <v-row>
        <v-col md="8">
          <p class="m-0 mb-2 font-size-16 font-weight-600">Search Criteria</p>
        </v-col>
        <v-col md="4" class="text-right">
          <div class="close-btn">
            <v-btn
              fab
              outlined
              x-small
              :disabled="dataLoading"
              elevation="2"
              hide-details
              dense
              color="red lighten-1"
              class="red--background"
              v-on:click="clearSearch"
            >
              <v-icon dark class="text-white"> mdi-close </v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <ul>
        <template v-for="(row, index) in searchObject">
          <li :key="index" class="font-weight-500 font-size-14">
            {{ row.text }}
            <template v-if="lodash.isArray($route.query[row.key])">
              between
              <span class="font-weight-700"
                >{{ formatDate($route.query[row.key][0]) }} and
                {{ formatDate($route.query[row.key][1]) }}</span
              >
            </template>
            <template v-else>
              contains
              <span class="font-weight-700">{{ $route.query[row.key] }}</span>
            </template>
            <template v-if="lodash.findLastKey(searchObject) != index">
              (and),
            </template>
            <template v-else> . </template>
          </li>
        </template>
      </ul>
      <v-btn
        text
        :disabled="dataLoading"
        color="cyan"
        v-on:click="advancedSearch"
      >
        Advanced Search <v-icon right>mdi-arrow-right</v-icon>
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
export default {
  mixins: [CommonMixin],
  props: {
    dataLoading: {
      type: Boolean,
    },
    allowedSearchFields: {
      type: Array,
      required: true,
      default: () => {
        return new Array();
      },
    },
  },
  methods: {
    clearSearch() {
      this.$emit("clear-search", true);
    },
    advancedSearch() {
      this.$emit("advanced-search", true);
    },
  },
  computed: {
    searchObject() {
      let result = new Array();
      for (let i = 0; i < this.allowedSearchFields.length; i++) {
        if (this.$route.query[this.allowedSearchFields[i].key]) {
          if (
            this.lodash.isArray(
              this.$route.query[this.allowedSearchFields[i].key]
            )
          ) {
            if (
              this.lodash.isEmpty(
                this.$route.query[this.allowedSearchFields[i].key]
              ) === false
            ) {
              result.push(this.allowedSearchFields[i]);
            }
          } else {
            result.push(this.allowedSearchFields[i]);
          }
        }
      }
      return result;
    },
  },
};
</script>
